import React from "react"
import styled from "styled-components"
import SEO from "../../components/seo"
import Layout from "../../layouts/layout"
import Banner from "../../components/banner"
// import style from "../../assets/global-style"

const Container = styled.div`
  margin-bottom: 5.7rem;
  & > img {
    width: 100%;
    height: 100%;
  }

  .desc-box {
    background: url("http://maps.yncce.cn/website/fcjj1.jpg") center no-repeat;
    background-size: cover;
    width: 100%;
    height: 30rem;
    margin: 50px 0;
    .box {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      height: 100%;
      padding: 40px;

      .desc {
        height: 100%;
        width: 45rem;
        margin-left: -160px;
        padding: 30px 20px;
        background-color: rgba(255, 255, 255, 0.2);
        overflow-y: scroll;

        &:hover {
        ::-webkit-scrollbar-thumb {
          background-color: rgba(255, 255, 255, 0.7);
        }
      }

        &::-webkit-scrollbar {
          width: 16px;
          background-color: rgba(255, 255, 255, 0.4);
          padding: 10px 0;
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent;
          transition: background-color 2s;
        }

        p {
          text-indent: 2em;
          letter-spacing: 2px;
          font-size: 20px;
          color: white;
        }
      }
    }
  }
`

const Page = () => {
  return (
    <Layout>
      <SEO title="房产经纪" />
      <Banner img="http://cdn.jerryshi.com/gtkj/20200107154943.jpg" />
      <Container>
        <div className="desc-box">
          <div className="box">
            <div className="desc">
              <p>
                云南梦居房地产有限公司成立于2010年9月，主要从事房地产二级市场代理、三级市场经纪业务，集房地产营销策划代理、政策法规及信息咨询、投资顾问、市场调研、项目可行性研究、物业评估、二手楼按揭、商业物业贷款、房产按揭等各类业务于一体的专业地产公司。
              </p>
              <p>
                人才是公司发展的基础，尤其以“专业”和“服务”为主导产品的中介行业。现公司员工80%以上具有大专以上学历，其中汇集了一批高素质人才；公司高中层管理人员在专业技术、组织管理、实际操作和理论研究等方面都各有专长，资历扎实经验丰富。梦居房产员工以素质优良、经验丰富、专业高效、尽职尽责的特点赢得了广大客户的赞誉。
              </p>
              <p>
                自成立以来，公司坚持“规范经营、合理收费、客户为本、以服务立基”的经营宗旨，为客户提供最及时的市场信息，最省时省力、最实惠、最便捷的服务。我们的信念是“客户是我们永远的伙伴,让每个客户脸上都写满笑意是每个现代人义不容辞的责任”。
              </p>
              <p>
                目前，公司已经在云南省昆明四个区﹑澄江县﹑大理市﹑丽江市﹑罗平县﹑元阳县等设立多家分公司，业务将辐射面亦遍布云南省各行政区域。
              </p>
            </div>
          </div>
        </div>
        <img src="http://maps.yncce.cn/website/fcjj.png" alt="1" />
      </Container>
    </Layout>
  )
}

export default Page
